import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom'
import ScrollToTop from './ScrollToTop';
import 'assets/css/style.scss'
import 'react-tippy/dist/tippy.css';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<BrowserRouter >
  <ScrollToTop />
  <App />
</BrowserRouter>);