import { Link } from "react-router-dom";
import React, { useContext } from "react";
import { CartContext } from "global/CartContext";
import { getCartProductTotalPrice, getCartTotalPrice } from "utils/product";
import { CURRENCY } from "utils/constant";
import { useNavigate } from "react-router-dom";


const OrderDetails = ({ shippingPrice, handleSubmitCheckoutForm }) => {
  const { shoppingCart } = useContext(CartContext);
  const navigate = useNavigate();

  return (
    <div className="order-details-area-wrap checkout-item">
      <h4 className="mb-3">Your Order</h4>
      <div className="order-details-table table-responsive">
        <table className="table table-borderless">
          <thead>
            <tr>
              <th>Products</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {shoppingCart?.map((product) => (
              <tr className="cart-item">
                <td>
                  <span className="product-title">{product?.name}</span>
                  <span className="product-quantity">
                    × {product?.quantity}
                  </span>
                </td>
                <td>
                  {CURRENCY +
                    getCartProductTotalPrice(shoppingCart, product).toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr className="cart-subtotal">
              <th>Subtotal</th>
              <td>{CURRENCY + getCartTotalPrice(shoppingCart)}</td>
            </tr>
            <tr className="shipping">
              <th>Shipping cost</th>
              <td>
                <span>
                  {shippingPrice}
                </span>
              </td>
            </tr>
            <tr className="final-total">
              <th>Crypto currency</th>
              <td>
                <span className="total-amount">
                  <td>1 ETH</td>
                </span>
              </td>
            </tr>
            <tr className="final-total">
              <th>Total</th>
              <td>
                <span className="total-amount">
                  {CURRENCY + (getCartTotalPrice(shoppingCart) + shippingPrice).toFixed(2)}
                </span>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div className="order-details-footer">
        <p>
          Your personal data will be used to process your order in our
          <Link href="/">
            <a className="text-danger"> privacy policy.</a>
          </Link>
        </p>
        <div className="form-check mt-2">
          <input
            type="checkbox"
            id="privacy"
            className="form-check-input"
            required
          />
          <label htmlFor="privacy" className="form-check-label">
            I have read and agree to the website terms and conditions
          </label>
        </div>
        <button type="button" onClick={() => navigate("/purchase-succeed")} className="p-button">
          Place Order
        </button>
      </div>
    </div>
  );
};

export default OrderDetails;
