import React from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { useNavigate } from 'react-router-dom';

const Account = () => {

    const navigate = useNavigate();
    const items = [
        {
            title: "Your Orders",
            img: "/assets/images/no-placeholder/order-icon.png",
            comments: "Track, return, or buy things again",
            link: "/orders"
        },
        {
            title: "Your Payments",
            img: "/assets/images/no-placeholder/your-payments.png",
            comments: "View all transactions, manage payment methods and settings",
            link: "/your-payments"
        },
        {
            title: "Your Profiles",
            img: "/assets/images/no-placeholder/your-profile.png",
            comments: "View profiles",
            link: "/profiles"
        },
        {
            title: "Customer Service",
            img: "/assets/images/no-placeholder/contact-us.png",
            comments: "Customer Service",
            link: "/membership"
        },
    ]


    return (
        <div className='account-page-wrapper'>
            <Header className="header01" />
            <div className="themes-flat-container tf-section">
                <h2 className='text-center'>Your Account</h2>
                <div className="account-wrap">
                    <div className="account-content">
                        {items.map(item => <div key={item.title} className="item" onClick={() => navigate(item.link)}>
                            <img className="img" src={`${item.img}`} alt={`${item.title}`} />
                            <div className="desc">
                                <div className="title">
                                    {`${item.title}`}
                                </div>
                                <div className="comments">
                                    {`${item.comments}`}
                                </div>
                            </div>
                        </div>)}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Account;
