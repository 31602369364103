import './App.scss';
import "slick-carousel/slick/slick.css";
import { Routes, Route } from 'react-router-dom';
import { Provider as ReduxProvider } from "react-redux";
import routes from './pages/index'
import store from "./store";

import CartContextProvider from "global/CartContext";
import CompareContextProvider from "global/CompareContext";
import ProductsContextProvider from "global/ProductsContext";
import WishlistContextProvider from "global/WishlistContext";



function App() {
    return (
        <CartContextProvider>
            <CompareContextProvider>
                <WishlistContextProvider>
                    <ProductsContextProvider>
                        <ReduxProvider store={store}>
                            {/* <MyContextProvider> */}
                            <Routes >
                                {
                                    routes.map((data, index) => (
                                        <Route onUpdate={() => window.scrollTo(0, 0)}
                                            exact={true} path={data.path} element={data.component} key={index} />
                                    ))
                                }
                            </Routes>
                            {/* </MyContextProvider> */}
                        </ReduxProvider>
                    </ProductsContextProvider>
                </WishlistContextProvider>
            </CompareContextProvider>
        </CartContextProvider>
    );
}

export default App;