import { createContext } from "react"
import products from 'assets/fake-data/data-products.json'

export const ProductsContext = createContext([]);

const ProductsContextProvider = ({ children }) => {

    return (
        <ProductsContext.Provider value={{ products: [...products] }}>
            {children}
        </ProductsContext.Provider>
    );
};

export default ProductsContextProvider;