import React, { useContext } from 'react'
import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';
import { CartContext } from "global/CartContext";
import { Link } from 'react-router-dom';

export default function PurchaseSucceed() {

    const { shoppingCart } = useContext(CartContext);

    return (
        <div className='purchase-succeed-page-wrapper'>
            <Header className="header01" />
            <div className="themes-flat-container mt-5">
                <div className="row">
                    <div className="col-12 center">
                        <h2 className="tf-title-heading ct style-1">
                            Your order ######### has been placed.
                        </h2>
                        <h3 className='text-center'><Link to={`/deals`}>Continue shopping</Link></h3>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}