import React from "react";
import { Row, Col } from "react-bootstrap";

const AddNewAddressForm = ({ className = "" }) => {
  return (
    <div className="add-new-address-form-wrap form-default">
      <form name="add-new-address-form">
        <div className="form-group">
          <label htmlFor="name" className="required">
            Your Name
          </label>
          <input
            type="text"
            id="name"
            placeholder="Your Name"
            className="form-control"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="country" className="required">
            Country
          </label>
          <select name="country" id="country" className="form-control">
            <option value="Afghanistan">Afghanistan</option>
            <option value="Albania">Albania</option>
            <option value="Algeria">Algeria</option>
            <option value="Armenia">Armenia</option>
            <option value="Bangladesh">Bangladesh</option>
            <option value="India">India</option>
            <option value="Pakistan">Pakistan</option>
            <option value="England">England</option>
            <option value="London">London</option>
            <option value="London">London</option>
            <option value="China">China</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="street-address" className="required">
            Street address
          </label>
          <input
            type="text"
            id="street-address"
            placeholder="Street address Line 1"
            className="form-control"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="town" className="required">
            Town / City
          </label>
          <input
            type="text"
            id="town"
            placeholder="Town / City"
            className="form-control"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="state">State / Division</label>
          <input
            type="text"
            id="state"
            placeholder="State / Division"
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label htmlFor="postcode" className="required">
            Postcode / ZIP
          </label>
          <input
            type="text"
            id="postcode"
            placeholder="Postcode / ZIP"
            className="form-control"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone</label>
          <input
            type="text"
            id="phone"
            placeholder="Phone"
            className="form-control"
          />
        </div>
      </form>
    </div>
  );
};

export default AddNewAddressForm;
