import React, { useContext } from 'react'
import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';
import CartProducts from 'components/cart/CartProducts';
import CalculateShipping from 'components/cart/CalculateShipping';
import { CartContext } from "global/CartContext";
import EmptyCart from 'components/cart/EmptyCart';

export default function Cart() {

    const { shoppingCart } = useContext(CartContext);

    return (
        <div className='cart-page-wrapper'>
            <Header className="header01" />
            <section className="tf-login tf-section">
                <div className="themes-flat-container">


                    {shoppingCart.length > 0 && (
                        <div>
                            <div className="row">
                                <div className="col-12">
                                    <h2 className="tf-title-heading ct style-1">
                                        SHOPPING CART
                                    </h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-8">
                                    <CartProducts />
                                </div>
                                <div className="col-4">
                                    <CalculateShipping />
                                </div>
                            </div>

                        </div>
                    )}

                    {shoppingCart.length <= 0 && (
                        <div className="empty-cart-wrapper">
                            <EmptyCart />
                        </div>
                    )}

                </div>

            </section>
            <Footer />
        </div>
    )
}