import { useState, useEffect } from "react";
import { ConfigProvider } from 'antd';
import { useMount } from 'hooks';

const AntdWrap = ({ className, children }) => {

    const mounted = useMount();
    if (!mounted) return null;

    return (
        <ConfigProvider theme={{ token: { colorPrimary: '#5142fc', }, }} >
            <div className={`antd-wrap ${className ? className : ''}`}>
                {children}
            </div >
        </ConfigProvider>
    );
};

export default AntdWrap;