import * as types from "../action-types";
import { getToken } from "../../utils/auth";
import { PREFIX } from "utils/constant";
import lscache from "lscache";

const localUser = lscache.get(PREFIX + "-user") || {};

const initUserInfo = {
  userName: "",
  role: "",
  avatar: "",
  currentAccount: "",
  token: '',
  email: "",
  ...localUser
};

export default function user(state = initUserInfo, action) {
  switch (action.type) {
    case types.USER_SET_USER_TOKEN:
      return {
        ...state,
        token: action.token
      };
    case types.USER_SET_USER_INFO:
      return {
        ...state,
        userName: action.userName,
        role: action.role,
        avatar: action.avatar,
      };
    case types.USER_CONNECT_WALLET:
      return {
        ...state,
        currentAccount: action.currentAccount
      };
    case types.USER_RESET_USER:
      return {};
    default:
      return state;
  }
}
