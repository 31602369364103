import React, { useContext, useEffect, useState } from 'react'
import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';
import { CartContext } from "global/CartContext";
import { Space, Table, Tag, Tabs, Pagination } from 'antd';
import AntdWrap from 'components/ui/AntdWrap';

function Orders() {
    const { shoppingCart } = useContext(CartContext);

    const items = [
        {
            key: '1',
            label: `Orders`,
            children: `Orders`,
            children: <TabContent data={Array(22).fill(1).map((item, index) => index + 1)} />,
        },
        {
            key: '2',
            label: `Buy Again`,
            children: `Buy Again`,
        },
        {
            key: '3',
            label: `Not Yet Shipped`,
            children: `Not Yet Shipped`,
        },
        {
            key: '4',
            label: `Digital Orders`,
            children: `Digital Orders`,
        },
        {
            key: '5',
            label: `Local Store Orders`,
            children: `Local Store Orders`,
        },
        {
            key: '6',
            label: `Cancelled Orders`,
            children: `Cancelled Orders`,
        },
    ];

    const onChange = (key) => {
        console.log(key);
    };

    return (
        <div className='order-page-wrapper'>
            <Header className="header01" />
            <div className="themes-flat-container tf-section">
                <h2 className='text-center'> Your Orders </h2>
                <AntdWrap className={`content`}>
                    <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
                </AntdWrap>
            </div>
            <Footer />
        </div>
    )
}



const TabContent = ({ data, className }) => {

    const [defaultCurrent, setDefaultCurrent] = useState(1)
    const [defaultPageSize, setDefaultPageSize] = useState(5)

    const [pageList, setPageList] = useState([])

    const onPageChange = (page, pageSize) => {
        let start = ((page - 1) * pageSize);
        let end = start + pageSize;
        setPageList(data.slice(start, end))
    }

    useEffect(() => {
        setPageList(data.slice(0, defaultPageSize))
    }, [])


    return (
        <div className={`tab-content ${className || ''}`}>
            <div className="search">
                <span>2 orders placed in </span>
                <select name="" id="">
                    <option value="past 3 months">past 3 months</option>
                    <option value="past 6 months">past 6 months</option>
                </select>
            </div>
            <div className="list">
                {pageList.map((item, index) => (
                    <div key={index} className="list-item">
                        <div className="header">
                            <div className="date">
                                <div>ORDER PLACED</div>
                                <div>August 1, 2022</div>
                            </div>
                            <div className="price">
                                <div>TOTAL</div>
                                <div>$9.99</div>
                            </div>
                            <div className="address">
                                <div>SHIP TO</div>
                                <div>address</div>
                            </div>
                            <div className="order">
                                <div className='number'> ORDER # {item} 123123123123</div>
                                <div className='detail'>
                                    <span>Order Detail</span><span>|</span><span>Invoice</span>
                                </div>
                            </div>
                        </div>
                        <div className="body">
                            <div className="products">
                                <h6 className="data">Delivered Aug 3,2022</h6>
                                <div className="comments">Package was left inside the mailbox</div>

                                {[1, 2].map(product => (
                                    <div className="product">
                                        <img className='image' src="assets/images/product/product-11.jpg" alt="" />
                                        <div>
                                            <h5 className='name'>Wimbledon Cross Court Cap</h5>
                                            <div className="comments">Return window closed on Sep 4,2022</div>
                                            <div className="price">$9.99</div>
                                            <div className="buttons">
                                                <button className='p-button'>Buy again</button>
                                                <button className='p-button'>View your item</button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="buttons">
                                <button className='p-button'>Ask Product Question</button>
                                <button className='p-button'>Leave seller feedback</button>
                                <button className='p-button'>Write a product review</button>
                            </div>

                        </div>
                        <div className="footer">
                            <button className='p-button'>Archive order</button>
                        </div>
                    </div>
                ))}

            </div>

            <div className="pagination">
                <Pagination onChange={onPageChange} defaultCurrent={defaultCurrent} defaultPageSize={defaultPageSize} total={data.length} />
            </div>
        </div>
    )
}


export default Orders;