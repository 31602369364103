import React from 'react'
import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';
import CheckoutPageContent from 'components/checkout/CheckoutPageContent';

export default function Checkout() {
    return (
        <div className='cart-page-wrapper'>
            <Header className="header01" />
            <section className="tf-login tf-section">
                <div className="themes-flat-container">
                    <div className="checkout-page-content row">
                        <CheckoutPageContent />
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}
