
import React, { useContext } from 'react'
import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';
import { CartContext } from "global/CartContext";
import Accordion from 'react-bootstrap/Accordion';

export default function Membership() {

    const { shoppingCart } = useContext(CartContext);

    return (
        <div className='membership-page-wrapper'>
            <Header className="header01" />
            <div className="themes-flat-container tf-section">
                <h2 className='text-center'> Membership </h2>
                <div className="content">



                    <div className="status-wrap">
                        <div className="status">
                            <i className="icon-h-09"></i>
                            <span className="status-str">Account Status</span>
                        </div>
                        <div className="desc mt-3">
                            You are currently not a member.
                        </div>
                    </div>
                    <h3 className="settings mb-6 pb-0 mt-4">
                        Jack's Audible Settings
                    </h3>
                    <div className="tt-collapse-block mt-20">
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>PAYMENT PREFERENCES & PURCHASES</Accordion.Header>
                                <Accordion.Body>
                                    <div className="text1">Membership</div>
                                    <div className="text2">Current Status: <span className="highlight">Free Trial Eligible</span></div>
                                    <div className="text3">Learn about Audible membership</div>
                                    <div className="text4">Browse audio books</div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                    <div className="tt-collapse-block mt-20">
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    MEMBERSHIP OPTIONS & HELP
                                </Accordion.Header>
                                <Accordion.Body>
                                    <div>
                                        <div className="text3">Give an Audible Gift Membership</div>
                                        <div>
                                            <div>Cancel Membership <span>Toll free: 1 (866) xxx-xxxx</span></div>
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>

            </div>
            <Footer />
        </div>
    )
}