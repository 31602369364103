import React, { useContext, useState, createRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Col, Row } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import BillingForm from "components/checkout/BillingForm";
import OrderDetails from "components/checkout/OrderDetails";
import { CartContext } from "global/CartContext";
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import AddNewAddressForm from "./AddNewAddressForm";
import AddBillingAddress from "./AddBillingAddress";

import { CURRENCY } from "utils/constant";


const CheckoutPageContent = () => {
  const { shoppingCart } = useContext(CartContext);
  const [pageTitle, setPageTitle] = useState('')

  const [shippingPrice, setShippingPrice] = useState(0)

  const [addNewAddressShow, setAddNewAddressShow] = useState(false)
  const handleAddNewAddressClose = () => setAddNewAddressShow(false);
  const handleAddNewAddressShow = () => setAddNewAddressShow(true);
  const checkoutFormRef = createRef();
  const handleSubmitCheckoutForm = () => {

  }

  useEffect(() => {
    setPageTitle(`CHECKOUT (${shoppingCart.length} items)`);
  }, [])


  return (
    <div className="container-fluid-custom-mobile-padding">
      <h1 className="tt-title-subpages mb-5">
        <Link to="/cart" >{pageTitle}</Link>
      </h1>

      <form ref={checkoutFormRef} name="checkout-form" action="">
        <Row>
          <Col lg={6} xl={8}>
            <CheckoutDetail handleAddNewAddressShow={handleAddNewAddressShow} setShippingPrice={setShippingPrice} />
          </Col>

          <Col lg={6} xl={4} className="ml-auto">
            <OrderDetails shippingPrice={shippingPrice} handleSubmitCheckoutForm={handleSubmitCheckoutForm} />
          </Col>
        </Row>
      </form>

      {addNewAddressShow && <AddNewAddress show={addNewAddressShow}
        handleAddNewAddressClose={handleAddNewAddressClose}
        handleAddNewAddressShow={handleAddNewAddressShow} />}
    </div>
  );
};


function CheckoutDetail({ handleAddNewAddressShow, setShippingPrice }) {
  const [bodyKeys, setBodyKeys] = useState(['address', 'payment', 'shipping']);
  const handleBodyShow = (bodyKey) => {
    if (bodyKeys.includes(bodyKey)) {
      setBodyKeys(bodyKeys.filter(item => item !== bodyKey));
    } else {
      setBodyKeys([...bodyKeys, bodyKey]);
    }
  }

  return (
    <div>
      <div className="accordion-container">
        <div className="accordion-item">
          <div className="header" onClick={() => handleBodyShow('address')}>
            <h4 className={`title active`}>1 Shipping address</h4>
            <div className="comments">
              <i className={`${bodyKeys.includes('address') ? 'icon-e-16' : 'icon-e-13'}`}></i>
            </div>
          </div>
          <div className="body" style={{ display: `${bodyKeys.includes('address') ? 'block' : 'none'}` }}>
            <ShippingAddress className={`shipping-address-wrap`} handleAddNewAddressShow={handleAddNewAddressShow} />
          </div>
        </div>
        <div className="accordion-item">
          <div className="header" onClick={() => handleBodyShow('payment')}>
            <h4 className={`title active`}>2 Payment method</h4>
            <div className="comments">
              <i className={`${bodyKeys.includes('payment') ? 'icon-e-16' : 'icon-e-13'}`}></i>
            </div>
          </div>
          <div className="body" style={{ display: `${bodyKeys.includes('payment') ? 'block' : 'none'}` }}>
            <PaymentMethod className={'payment-method-wrap'} />
          </div>
        </div>

        <div className="accordion-item">
          <div className="header" onClick={() => handleBodyShow('shipping')} >
            <h4 className={`title active`}>3 Shipping</h4>
            <div className="comments">
              <i className={`${bodyKeys.includes('shipping') ? 'icon-e-16' : 'icon-e-13'}`}></i>
            </div>
          </div>
          <div className="body" style={{ display: `${bodyKeys.includes('shipping') ? 'block' : 'none'}` }}>
            <ReviewItemsAndShipping className="review-items-and-shipping-wrap" setShippingPrice={setShippingPrice} />
          </div>
        </div>
      </div>
    </div>
  );
}

function ShippingAddress({ className, handleAddNewAddressShow }) {
  return (
    <div className={className}>
      {['radio'].map((type) => (
        <div key={`default-${type}`} className="mb-3">
          <Form.Check type={type} name="group1" label={`58 Lake View Ave. Poughkeepsie, NY 12601`} />
          <Form.Check type={type} name="group1" label={`288 Bank Avenue Brooklyn, NY 11228`} />
        </div>
      ))}
      <div className="add-new-address">
        <Button className="p-button" variant="light" size="sm" onClick={handleAddNewAddressShow}><i class="icon-f-82"></i></Button>
      </div>
      <div className="use-this-address">
        <Button className="p-button" variant="light" size="sm">Use this address</Button>
      </div>
    </div>
  )
}

function AddNewAddress({ show, handleAddNewAddressClose }) {
  return (
    <>
      <Modal className="add-new-address-modal" show={show} onHide={handleAddNewAddressClose} width={800}>
        <Modal.Header closeButton>
          <Modal.Title>Add a new shipping address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddNewAddressForm />
        </Modal.Body>
        <Modal.Footer>
          <button className="p-button" onClick={handleAddNewAddressClose}> Close </button>
          <button className="p-button" onClick={handleAddNewAddressClose}> Save Changes </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function PaymentAccordionCustomToggle({ children, type, name, label, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () => { });
  return (
    <Form.Check type={`radio`} name={name} onClick={decoratedOnClick} label={label} />
  );
}

function PaymentMethod({ className }) {
  return (
    <div className={`payment-method-container ${className}`}>
      <h6>Payment</h6>
      <div className="comments">All transation are secure and enrypted.</div>

      <Form className=" form-default">
        <Accordion>
          <Card>
            <Card.Header>
              <PaymentAccordionCustomToggle eventKey="0" name="group1" label="Credit card" />
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body className="credit">
                <div className="form-group">
                  <input type="text" placeholder="Card number" className="form-control" />
                </div>
                <div className="form-group">
                  <input type="text" placeholder="Cardholder name" className="form-control" />
                </div>
                <div className="date">
                  <div className="form-group">
                    <input type="text" placeholder="Expiration date(MM/YY)" className="form-control" />
                  </div>
                  <div className="form-group">
                    <input type="text" placeholder="Security code" className="form-control" />
                  </div>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <PaymentAccordionCustomToggle eventKey="1" name="group1" label="Paypal" />
            </Card.Header>
          </Card>
          <Card>
            <Card.Header>
              <PaymentAccordionCustomToggle eventKey="2" name="group1" label="MOMO Token" />
            </Card.Header>
          </Card>
        </Accordion>
      </Form>


      <h6 className="billing">Billing address</h6>
      <div className="comments">Select the address that matchs your card or payment mothod</div>
      <Form>
        <Accordion defaultActiveKey="0">
          <Card>
            <Card.Header>
              <PaymentAccordionCustomToggle eventKey="0" name="billing-group" label="Same as shipping addresss" />
            </Card.Header>
          </Card>
          <Card>
            <Card.Header>
              <PaymentAccordionCustomToggle eventKey="1" name="billing-group" label="Use a defferent billing address" />
            </Card.Header>
            <Accordion.Collapse eventKey="1">
              <Card.Body className="billing-address">
                <AddBillingAddress />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Form>
    </div>
  )
}

function ReviewItemsAndShipping({ className, setShippingPrice }) {

  const shippings = [
    { name: "First Class Package", badge: "CHEAPEST", shippingPrice: 1.2 },
    { name: "Parcel Select Ground Cubic", shippingPrice: 2.2 },
    { name: "UPS Ground", shippingPrice: 3.1 },
    { name: "Priority Mail", shippingPrice: 4.3 },
  ]

  const [showShippingPriceTable, setShowShippingPriceTable] = useState(false);

  return (
    <div className={className}>
      <div className="shipping-header">
        <h6 className="comments">Pick up your shipping carrier.</h6>
        <button type="button" className="p-button" onClick={() => setShowShippingPriceTable(!showShippingPriceTable)}>Calculate shipping price</button>
      </div>
      <div className="shipping-item-wrap">
        {showShippingPriceTable && shippings.map(shipping => (
          <>
            <div className="shipping-item">
              <h5>{shipping.name}</h5>
              <div className="desc">
                <span className="badge">{shipping.badge}</span>
                <span className="text">Zone 1, Commercial Pricing</span>
              </div>
              <h6 className="desc2">Estimated delivery Wednesday 2/8 by 9:00 PM if shipped on 2/6</h6>
              <div className="desc3">Free Tracking</div>
              <div className="price">
                <div className="l">
                  <span>Save 25%</span>
                  <span className="old">$6.15 retail</span>
                </div>
                <div className="r">{CURRENCY + shipping.shippingPrice}</div>
              </div>
              <div className="footer">
                <span>Learn more & view rates</span>
                <button type="button" className="p-button" onClick={() => setShippingPrice(shipping.shippingPrice)}>Ship Now</button>
              </div>
            </div>
          </>
        ))}
      </div>
    </div>
  )
}

export default CheckoutPageContent;