import React from "react";
import { Row, Col } from "react-bootstrap";

export default function AddBillingAddress() {
    return (
        <form className="add-billing-address-container  form-default">
            <div className="form-group">
                <select name="country" id="country" className="form-control">
                    <option value="Afghanistan">Afghanistan</option>
                    <option value="Albania">Albania</option>
                    <option value="Algeria">Algeria</option>
                    <option value="Armenia">Armenia</option>
                    <option value="Bangladesh">Bangladesh</option>
                    <option value="India">India</option>
                    <option value="Pakistan">Pakistan</option>
                    <option value="England">England</option>
                    <option value="London">London</option>
                    <option value="London">London</option>
                    <option value="China">China</option>
                </select>
            </div>
            <Row>
                <Col md={6}>
                    <div className="form-group">
                        <input
                            type="text"
                            id="f_name"
                            placeholder="First Name"
                            className="form-control"
                            required
                        />
                    </div>
                </Col>
                <Col md={6}>
                    <div className="form-group">
                        <input
                            type="text"
                            id="l_name"
                            placeholder="Last Name"
                            className="form-control"
                            required
                        />
                    </div>
                </Col>
            </Row>
            <div className="form-group">
                <input
                    type="text"
                    id="com-name"
                    placeholder="Company"
                    className="form-control"
                />
            </div>

            <div className="form-group">
                <input
                    type="text"
                    id="address"
                    placeholder="Address"
                    className="form-control"
                    required
                />
            </div>
            <div className="form-group">
                <input
                    type="text"
                    id="apartment"
                    placeholder="Apartment, suite, etc. (optional)"
                    className="form-control"
                    required
                />
            </div>

            <Row>
                <Col md={4}>
                    <div className="form-group">
                        <input
                            type="text"
                            id="town"
                            placeholder="Town / City"
                            className="form-control"
                            required
                        />
                    </div>
                </Col>
                <Col md={4}>
                    <div className="form-group">
                        <select name="country" placeholder="State" id="country" className="form-control">
                            <option value="California">California</option>
                        </select>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="form-group">
                        <input
                            type="text"
                            id="postcode"
                            placeholder="Postcode / ZIP"
                            className="form-control"
                            required
                        />
                    </div>
                </Col>
            </Row>



            <div className="form-group">
                <input
                    type="text"
                    id="phone"
                    placeholder="Phone (optional)"
                    className="form-control"
                />
            </div>
        </form>
    )
}